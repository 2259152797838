
import {computed, defineComponent, onMounted, ref} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {dateConvert} from "@/core/filters/datatime";
import {useRoute} from "vue-router";
import {useRouter} from "vue-router";
import flatpickr from "flatpickr";
import {Turkish} from "flatpickr/dist/l10n/tr";
import 'flatpickr/dist/flatpickr.css';
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import reservationProductType from "@/core/data/reservationProductsTypetypes";
import AppCore from "@/core/appcore/AppCore";


interface IFormValues {
  InvoiceNo : string;
  GroupType : string;
  InvoiceDate: string;
  InvoiceDescription: string;
  CurType : string;
}


export default defineComponent({
  name: "bill-step-2",
  components: {
  },

  setup() {

    onMounted(() => {
      setCurrentPageBreadcrumbs("Faturalar", ["Cari Hesaplar"]);

      setDatePicker();

      paramsID.value = JSON.parse(route.params.id as string);
      const nonInvPrdPayload = {
        ReservationIDs: paramsID.value,
      };

      store.dispatch(Actions.RESERVATION_PRODUCTS_NONINVOICES_LIST, nonInvPrdPayload).then((prmData) => {
        AppCore.prsCore_Invoice.arrCheckedProducts = [];
        fillReservationList(prmData.data);
        arrNoninvoiceProductList.value = prmData.data;
        ccvAccountID.value = arrNoninvoiceProductList.value[0].items[0].AccountID;
        store.dispatch(Actions.ACCOUNT_DETAILS, {ID: ccvAccountID.value}).then((prmData) => {
          objAccDetails.value = prmData.data;
          //console.log(objAccDetails.value);
        });


      });

      store.dispatch(Actions.CUR_RATE_LIST, {}).then((prmData) => {
        if (prmData.data!== undefined && prmData.data.length>0 ){
          objCurRate.value = prmData.data[0];
        }
      });

    });


    const objCurRate = ref({});
    const objAccDetails = ref({});
    const route = useRoute();
    const router = useRouter();
    const paramsID = ref([]);
    const arrNoninvoiceProductList = ref([]);
    const arrReservationList = ref([]);
   // const arrCheckProductCost = ref([]);
    const objCheckProductCost = ref({TotalCost:0});
    const ccvAccountID = ref(0);


    function setDatePicker() {
      let tmpInvoiceDate = flatpickr("#InvoiceDate", {"locale": Turkish, dateFormat: "d-m-Y", defaultDate: 'today',});
    }


    function changeCur(prmCur){
      for (let i=0; i<arrNoninvoiceProductList.value.length; i++){
        let eachElement = arrNoninvoiceProductList.value[i];

        let myPrice = libTools.method.getCurParity((objCurRate.value as any), eachElement.Price, prmCur);
        let spnPriceCur = document.getElementById('spnNonInvoiceProduct_PriceCur_' + eachElement.ID) as HTMLElement;

        spnPriceCur.innerHTML = libTools.method.localeStA(myPrice, 2) + " " + prmCur;

          //console.log("USD Price :" + myUsdPrice + " " + "USD Cur :" +  myUsdCur);
      }
      calculateCheckProduct();
    }


    function getCheckedManuelCur(){
      const vvclass = document.getElementsByClassName("inputCurs");
      const checkboxid = document.getElementById("checkInput")  as HTMLInputElement;
      for (let i=0; i<vvclass.length; i++) {
        const eachElemnt = vvclass[i] as HTMLInputElement;
        if(checkboxid.checked){
          (eachElemnt.removeAttribute("disabled"));
          (eachElemnt.classList.remove('text-muted'));
        }else {
          (eachElemnt.setAttribute("disabled", ""));
          (eachElemnt.classList.add('text-muted'));
        }
      }
    }


    function getReservationByID(prmReservationID) {
      for (let i=0; i<arrReservationList.value.length; i++){
        let each = arrReservationList.value[i];
       // console.log(arrReservationList);
        if(each.ReservationID === prmReservationID){
          return each;
        }
      }
      return null;
    }


    function fillReservationList (prmData) {
      if (prmData !== undefined){
        for (let i=0; i<prmData.length; i++){
          let each = prmData[i];
          let eachReservation = getReservationByID(each.ReservationID);
          //console.log(eachReservation);

          if (eachReservation==null){
            arrReservationList.value.push({
              ReservationID: each.ReservationID,
              VoucherNo: each.items[0].VoucherNo,
              Total: parseFloat(each.Price),

            });
          }else {
            eachReservation.Total +=  parseFloat(each.Price);
          }
        }
      }
    }


    function calculateCheckProduct(){
      const vvClassName = "checkedProduct";
      const arrElements = document.getElementsByClassName(vvClassName);
      AppCore.prsCore_Invoice.arrCheckedProducts = [];
      objCheckProductCost.value.TotalCost = 0;

      for (let i=0; i<arrElements.length; i++){
        let eachElement = arrElements[i] as HTMLInputElement;
        if (eachElement.checked) {
          let productID = eachElement.getAttribute("productID");
          const objProduct = getInvoicesByProductID(productID);

          //objCheckProductCost.value.TotalCost += parseFloat(objProduct.Price);
          objCheckProductCost.value.TotalCost += libTools.method.getCurParity((objCurRate.value as any), objProduct.Price, objFormValues.value.CurType);

          AppCore.prsCore_Invoice.arrCheckedProducts.push(objProduct);
        }

      }
    }


     const objFormValues = ref<IFormValues>({
      InvoiceNo : "",
      GroupType : "NONGROUP",
      InvoiceDescription : "",
      InvoiceDate : dateConvert.method.convertToDate(dateConvert.method.getTodayYMD()),
      CurType : "TRY",
    })



    function clickNext() {
      if(objFormValues.value.InvoiceNo !== ""){
        if(objFormValues.value.InvoiceDate !== ""){
            if(AppCore.prsCore_Invoice.arrCheckedProducts.length>0){
              router.push({path: "/apps/sys/accounting/invocie_container"});
              calculateCheckProduct();
              AppCore.prsCore_Invoice.objFormValues = objFormValues.value;
              AppCore.prsCore_Invoice.objAccDetails = objAccDetails.value;
              AppCore.prsCore_Invoice.objCurRate = objCurRate.value;
            }else{
              libTools.method.swalError("Tamam", "En az bir ürün seçiniz." );
            }
        }else {
          libTools.method.swalError("Tamam", "Fatura tarihi giriniz." );
        }
      }else{
        libTools.method.swalError("Tamam", "Fatura no giriniz." );
      }

    }



    function getInvoicesByProductType (prmReservationID, prmProductType){
      const confirmResults = arrNoninvoiceProductList.value.filter(obj =>{
        return obj.ReservationID === prmReservationID + "" && obj.ProductType === prmProductType ;
      })
      return confirmResults;
    }


    function getInvoicesByProductID (prmID){
      const confirmRes = arrNoninvoiceProductList.value.find(obj =>{
        //console.log("getInvoicesByProductID " + obj.ID + " " +  prmID + " ");
        return obj.ID === prmID + "" ;
      })
      //console.log(confirmRes);
      return confirmRes;
    }

    function checkAllProducts(prmReservationID) {
      const vvclass = document.getElementsByClassName("BillStep2_checkedReservationID" + prmReservationID);
      const checkboxid = document.getElementById("checkAll_" + prmReservationID) as HTMLInputElement;

      for (let i=0; i<vvclass.length; i++) {
        let eachElement = vvclass[i] as HTMLInputElement;
        if(checkboxid.checked){
          (eachElement.checked = true);
        }else {
          (eachElement.checked = false);
        }
      }
      calculateCheckProduct();

    }


    return {
      AppCore,
      libTools,
      dateConvert,
      reservationProductType,
      arrReservationList,
      getInvoicesByProductType,
      arrNoninvoiceProductList,
      paramsID,
      objCurRate,
      getCheckedManuelCur,
      objAccDetails,
     // clickProductsByProductType,
      calculateCheckProduct,
      objFormValues,
      clickNext,
      checkAllProducts,
      objCheckProductCost,
      changeCur,
    };
  },
});
